import React, { useState } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_POST } from "../../apollo/queries/posts";
import { useQueryClient } from "react-query";
import { richTextRenderer } from "../../helpers/richTextRenderer";
import { GET_LAST_THREE_POST } from "../../apollo/queries/posts";
import { formatDistanceToNow } from "date-fns";
import { es } from "date-fns/locale";
import { Helmet } from "react-helmet";
import { GiSunglasses } from "react-icons/gi";
import { PiHandsClappingThin } from "react-icons/pi";
import {
  Header,
  TopMenu,
  // RightBannerSpot,
  ResponsiveWrap,
  Avatar,
  RenderIf,
  ImageSkeletonCard,
  ReactShare,
  FloatingButton,
  Divider,
  Footer,
} from "components";
import { IBlogPost } from "types";
import {
  useArticleData,
  useIncrementViewCount,
  useIncrementReactions,
} from "hooks";
import { useDebounce } from "usehooks-ts";
import { MAX_NUMBER_OF_REACTION_PER_SESSION } from "./constants";
import { GiJerusalemCross } from "react-icons/gi";
import Comments from "./Comments";

const PostPage = () => {
  const inProduction = process.env.NODE_ENV === "development";
  const queryClient = useQueryClient();
  const { id } = useParams<{ id: string }>();
  const { pathname } = useLocation();

  const [isReactionDisabled, setReactionDisabled] = useState(false);
  const sessionStorageKey = `reactionCount_${id}`;

  const [reactions, setReactions] = useState(0);
  const debouncedReactions = useDebounce(reactions, 1000);

  // get post data from Contentful with apollo client
  const { data } = useQuery(GET_POST, {
    variables: { id },
    onCompleted: (response) => console.log(response),
    onError: (error) => console.log("Error from the PostPage query", error),
  });

  // getting 3 most recent posts from contentful with apollo client
  const { data: lastThreePostsData } = useQuery(GET_LAST_THREE_POST);

  // get post views, shares and reactions from firebase
  const { data: articleViewsAndShares } = useArticleData(id, {
    onSuccess: (res) => {
      console.log({ res });
      setReactions(res?.reactions);
    },
  });

  // increments viewcount on firebase
  const { mutate: increateViewCount } = useIncrementViewCount({
    onSuccess: () => {
      queryClient.invalidateQueries(["articleData", id]);
    },
  });

  // increments reaction od firebase
  const { mutate: increaseReactions } = useIncrementReactions({
    onError: (error) => {
      console.error(error?.message);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["articleData", id]);
    },
  });

  // increment reaction count on session storage
  const incrementSessionStorageReactionCount = () => {
    const currentCountString = sessionStorage.getItem(sessionStorageKey);
    const currentCount = currentCountString ? parseInt(currentCountString) : 0;
    sessionStorage.setItem(sessionStorageKey, (currentCount + 1).toString());
  };

  const handleReactionClick = () => {
    const currentCountString = sessionStorage.getItem(sessionStorageKey);
    const currentCount = currentCountString ? parseInt(currentCountString) : 0;
    if (currentCount < MAX_NUMBER_OF_REACTION_PER_SESSION) {
      incrementSessionStorageReactionCount();
      setReactions((count) => count + 1);
    } else {
      // or any other UI feedback
      setReactionDisabled(true);
      alert("You have reached the maximum claps for this session!");
    }
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  React.useEffect(() => {
    // increase view count on component did mount, also when other article is clicked and id changes
    if (id && inProduction) {
      increateViewCount(id);
    }
    // creates reaction count on session storage if it doesnt exist
    const sessionStorageKey = `reactionCount_${id}`;
    if (!sessionStorage.getItem(sessionStorageKey)) {
      sessionStorage.setItem(sessionStorageKey, "0");
    }
    // checks for the reactionCount on sessionStorage and sets state
    const currentCountString = sessionStorage.getItem(sessionStorageKey);
    const currentCount = currentCountString ? parseInt(currentCountString) : 0;
    setReactionDisabled(currentCount >= MAX_NUMBER_OF_REACTION_PER_SESSION);
  }, [id]);

  React.useEffect(() => {
    if (
      debouncedReactions &&
      debouncedReactions !== articleViewsAndShares?.reactions
    ) {
      increaseReactions({ articleId: id, newCount: debouncedReactions });
    }
  }, [debouncedReactions]);

  // Mapping the embeded assets of the post in an object so they are passed to the renderer function
  const assetMap = data?.blogPost?.postBody?.links?.assets?.block.reduce(
    (acc: any, asset: any) => {
      acc[asset?.sys?.id] = asset?.url;
      return acc;
    },
    {}
  );

  return (
    <>
      {/* Add meta tags using Helmet */}
      <Helmet>
        <title>{data?.blogPost?.title || "Article Title"}</title>
        <meta
          property="og:title"
          content={data?.blogPost?.title || "Article Title"}
        />
        <meta
          property="og:description"
          content={data?.blogPost?.summary || "Article description"}
        />
        <meta
          property="og:image"
          content={data?.blogPost?.blogPicture?.url || "fallback_image_url.jpg"}
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="article" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={data?.blogPost?.title || "Article Title"}
        />
        <meta
          name="twitter:description"
          content={data?.blogPost?.summary || "Article description"}
        />
        <meta
          name="twitter:image"
          content={data?.blogPost?.blogPicture?.url || "fallback_image_url.jpg"}
        />
      </Helmet>
      <Header />
      <div className="sticky top-0 z-40">
        <TopMenu />
      </div>
      <div className="flex -mt-3">
        <Divider className="-mt-2" />
        <GiJerusalemCross className="text-5xl mx-2 font-bold" />
        <Divider className="-mt-2" />
      </div>
      <ResponsiveWrap className="mt-4">
        <div className="flex justify-between w-full mb-44">
          {/* Left Box */}
          <div className="w-full lg:w-3/4">
            <RenderIf isTrue={!data?.blogPost?.title}>
              <div className="h-6 w-2/3 bg-gray-200 animate-pulse mt-6 mb-2" />
              <div className="h-6 w-10/12 bg-gray-200 animate-pulse my-2" />
              <div className="h-6 w-56 bg-gray-200 animate-pulse mt-2 mb-6" />
            </RenderIf>
            <RenderIf isTrue={data?.blogPost?.title}>
              <h1 className="text-gray-700 font-black text-4xl lg:text-5xl abril-fatface-regular">
                {data?.blogPost?.title}
              </h1>
            </RenderIf>
            {/* Preambulo */}
            <div className="my-2 text-gray-400 italic text-xs">
              {data?.blogPost?.summary}
            </div>

            <RenderIf isTrue={!data?.blogPost?.blogPicture?.url}>
              <ImageSkeletonCard />
            </RenderIf>
            <RenderIf isTrue={data?.blogPost?.blogPicture?.url}>
              <img
                src={data?.blogPost?.blogPicture?.url}
                alt=""
                className="w-full"
              />
            </RenderIf>

            {/*  Author and article meta data */}
            <div className="w-full flex flex-col md:flex-row justify-between text-sm mt-6 mb-12">
              <div className="flex items-center mt-3">
                <Avatar url={data?.blogPost?.avatar?.url} />
                <div className="flex flex-col align-start">
                  <h2 className="ml-2 text-gray-500 font-medium">
                    Por <b>{data?.blogPost?.author}</b>
                  </h2>
                  {data && (
                    <h2 className="ml-2 mt-0.5 text-gray-500 font-medium">
                      {formatDistanceToNow(
                        new Date(data?.blogPost?.publishedDate),
                        { addSuffix: true, locale: es }
                      )}
                    </h2>
                  )}
                </div>
              </div>

              {/* Aplausos y vistas */}
              <div className="w-auto flex justify-end items-center mt-3 font-medium select-none">
                <h2 className="ml-4 text-gray-500 flex items-center">
                  <PiHandsClappingThin
                    className={`mr-1 w-8 h-8 cursor-pointer active:translate-y-0.5 ${
                      isReactionDisabled ? "text-gray-300" : "text-gray-500"
                    }`}
                    onClick={
                      isReactionDisabled ? undefined : handleReactionClick
                    }
                  />
                  {reactions}
                </h2>
                <h2 className="ml-8 mr-4 text-gray-500 flex items-center">
                  <GiSunglasses className="mr-1 w-8 h-8" />
                  {articleViewsAndShares?.views}
                </h2>
              </div>
            </div>

            <div className="mt-4 flex">
              {/*  Icons Bar  */}
              <div>
                <div className="sticky top-12 md:top-20 flex flex-col justify-center items-center space-y-1">
                  <ReactShare
                    url={window.location.href}
                    articleViewsAndShares={articleViewsAndShares}
                  />
                </div>
              </div>
              <div className="ml-4">
                {richTextRenderer(data?.blogPost?.postBody?.json, assetMap)}
              </div>
            </div>

            <div className="mt-6">
              <Comments
                postId={id}
                comments={articleViewsAndShares?.comments || []}
              />
            </div>
          </div>
          {/*  Right advertise Banners vertical Bar */}
          <div className="w-72 ml-6 hidden md:block">
            <h1 className="w-full text-xl text-right font-black text-gray-700">
              Artículos Recientes:
            </h1>
            <div className="mb-3">
              <Divider widthClass="w-full" mdWidthClass="w-full" />
            </div>
            {lastThreePostsData?.blogPostCollection?.items?.map(
              ({ title, blogPicture, sys, summary }: IBlogPost) => (
                <Link to={`./${sys?.id}`} key={title}>
                  <div className="flex flex-col mb-6 ml-2 hover:opacity-75">
                    <img src={blogPicture?.url} alt="article image" />
                    <h1
                      className="text-lg font-semibold text-gray-700 mb-1"
                      // style={{
                      //     display: '-webkit-box',
                      //     WebkitLineClamp: 2,
                      //     WebkitBoxOrient: 'vertical',
                      //     overflow: 'hidden',
                      //     textOverflow: 'ellipsis'
                      // }}
                    >
                      {title}
                    </h1>
                    <h3 className="text-xs text-gray-700">{summary}...</h3>
                  </div>
                </Link>
              )
            )}

            {/*  Right banner for Ads */}
            {/* <RightBannerSpot /> */}
          </div>
        </div>
      </ResponsiveWrap>
      <FloatingButton scrollY={500} />
      <Footer />
    </>
  );
};

export default PostPage;
